@import '~antd/dist/antd.css';

#root .bluekc-link {
    text-decoration: underline;
    color: #007cbd;
}

#root .bluekc-link:hover {
    text-decoration: none;
}

#root .bluekc-link:focus {
    color: #005a89;
}

#root .spinner {
    display: flex;
    justify-content: center;
    margin-top: 50vh;
}

#root .ant-btn-primary {
    background-color: #007cbd;
    border-color: #007cbd;
}

#root .bluekc-button {
    background-color: #007cbd;
    background: #007cbd;
    color: white;
}

#root button:not(:first-child) {
    margin-left: 0.5rem;
}

#root .bluekc-button:hover {
    background-color: #005a89;
    background: #005a89;
    color: white;
}

#root .bluekc-button-secondary:hover {
    color: #007cbd;
    border-color: #007cbd;
}
