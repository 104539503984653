#login-content {
    text-align: center;
}

#request-access-region {
    padding-top: 20px;
}

#request-access-link {
    font-size: 120%;
    text-decoration: underline;
}